<template>
  <div class="text-center py-5" >
        <h4>You don't have any subscription</h4>
        <p> Experience hassle-free excellence without the single process. Our subscription service offers top-tier quality by uniting elite creatives from 15+ countries worldwide, dedicated to realizing your next remarkable venture.</p>
        <a :href="absoluteUrl('/subscription')" class="btn btn-primary" target="_blank">Subscription now </a>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>