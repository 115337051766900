<template>
  <div class="card pricing-box ribbon-box right">
    <div v-if="plan.is_popular" class="ribbon-two ribbon-two-success"><span>Popular</span></div>
    <div class="card-body m-2 p-sm-4">
      <div class="d-sm-flex align-items-center mb-2">
        <div class="flex-grow-1">
          <h5 class="mb-sm-0 fw-semibold">{{plan.title}}</h5>
        </div>
        <div class="ms-auto">
          <h2 class="mb-0">
            <!-- <small v-if="['quarter','year'].includes(plan.billing_period)" class="fs-16"><del>${{plan.price_slash}}</del></small> -->
              ${{plan.unit_price}} 
            <small class="fs-13 text-muted text-capitalize">/ month</small>
          </h2>
        </div>
      </div>
      <div class="mb-3" v-if="plan.billing_period != 'month'">
        <div v-if="plan.price_slash" class="mb-2 text-success">
            ${{plan.price_slash }} Saved!
        </div>
        <h6 class="mb-0">${{ plan.actual_price }} billed {{plan.billing_period}}ly</h6>
      </div>
      <p class="text-muted">
        {{plan.description}}
      </p>
      <ul class="list-unstyled vstack gap-1">
        <li v-for="(attribute, fIndex) in attributes" :key="fIndex">
          <span class="me-1">
              <span v-if="attribute.type == 'check'"
                class="fs-15 align-middle" :class="plan.features[attribute.id] == 'supported' 
                  ? 'ri-checkbox-circle-fill text-success': 'ri-close-circle-fill'"> </span>
              <span v-if="attribute.type == 'input'">
                  <span class="fs-15 align-middle" :class="plan.features[attribute.id] 
                  ? 'ri-checkbox-circle-fill text-success': 'ri-close-circle-fill'"> </span>
                  {{plan.features[attribute.id]}}
              </span>
          </span>
          <span> {{attribute.name}}</span>
      </li> 
      </ul>
      <div class="mt-3 pt-2">
        <a v-if="current_plan" href="javascript:void(0);" class="btn btn-light w-100"
          >Current Plan</a
        >
        <a v-else @click="changePlan()" href="javascript:void(0);" class="btn btn-primary w-100">Change plan</a>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    props:{
        plan:{
          type: Object,
          required:true
        },
        current_plan:{
          type: Boolean,
          default: false
        },
    },
    computed: {
      attributes(){
        return this.$store.state.catalog.attributes
      }
    },
    methods:{
      changePlan(){
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to change your current subscription plan. Prorated adjustments applied.",
          reverseButtons:true,
          showCancelButton: true,
          confirmButtonColor: "#f1556c",
          cancelButtonColor: "#111",
          confirmButtonText: "Proceed",
        }).then((result) => {
          if (result.value) {
            let formData = new FormData();
            formData.append('plan_id', this.plan.id);
            formData.append('type', "change-plan");
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/subscriptions/update', formData)
                .then(response => {
                this.$store.dispatch("changeLoaderValue", true)
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                  this.$store.commit("SET_SUBSCRIPTION", response.data.data)
                  this.$store.dispatch("fetchActiveUser");
                }
            })
          }
        }); 
      }
    }
}
</script>