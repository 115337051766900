
<template>
<div class="plan-list">
  <div v-if="!isLoading" class="row gy-2 mb-6">
    <div class="col-12">
      <div v-if="subscription">
        <h5 class="mb-3">Your current plan</h5>
        <div class="card">
          <div class="card-body">
            <div class="">
              <div class="mb-2">
                <strong class="text-capitalize">Status: <span v-html="statusBadge(subscription.status)"> </span></strong>
              </div>
              <div>
                <h4 class="fw-bold text-primary"> {{subscription.plan.title}} Plan subscription at ${{ $filters.money_format(subscription.plan.actual_price)}} USD  per {{subscription.plan.billing_period}}</h4>
                <p>Subscription current period is between <strong>{{$filters.date_time(subscription.current_period_start, 1)}}</strong> and <strong>{{$filters.date_time(subscription.current_period_end, 1)}}</strong></p>
              </div>
            </div>
            <div>
              <button v-if="subscription.status == 'active'" @click="cancelSubscription" class="btn btn-danger">Cancel Subscription</button>
              <button v-else @click="resumeSubscription" class="btn btn-primary">Resume Subscription</button>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="text-center mb-4">
              <h4 class="fw-semibold fs-22">Plans & Pricing</h4>
              <p class="text-muted mb-4 fs-15">
                Simple pricing. No hidden fees. Advanced features for you business.
              </p>
              <div class="">
                <div class="d-inline-flex">
                  <ul class="nav justify-content-center nav-pills arrow-navtabs plan-nav rounded p-1"
                      id="pills-tab"
                      role="tablist">
                      <li class="nav-item" role="presentation">
                      <button
                          class="nav-link fw-semibold"
                          :class="{'active': billingPeriod == 'month'}"
                          data-bs-toggle="pill"
                          type="button"
                          role="tab"
                          :aria-selected="billingPeriod == 'month'"
                          @click="billingPeriod = 'month'" >
                          Monthly
                      </button>
                      </li>
                      <li class="nav-item" role="presentation">
                          <button
                              class="nav-link fw-semibold"
                              :class="{'active': billingPeriod == 'quarter'}"
                              data-bs-toggle="pill"
                              type="button"
                              role="tab"
                              :aria-selected="billingPeriod == 'quarter'"
                              @click="billingPeriod = 'quarter'">
                              Quarterly <span class="badge bg-success">10% Off</span>
                          </button>
                      </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="(plan, pIndex) in planList" :key="pIndex" class="col-md-12 col-lg-6 col-sm-6">
            <plan-card :plan="plan" 
            :current_plan="subscription.plan.id == plan.id" />
          </div>
        </div>
      </div>
      <subscription-call v-else />
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import PlanCard from "@/components/cards/PlanCard.vue"
import IsLoading from '@/components/IsLoading.vue'
import SubscriptionCall from '@/components/SubscriptionCall.vue'

export default {
  components:{
    IsLoading,
    PlanCard,
    SubscriptionCall
  },
  data(){
    return {
      billingPeriod: "month",
      isLoading: true,
    }
  },
  computed:{
    activeUser(){
      return this.$store.state.activeUser
    },
    subscription(){
      return this.$store.state.subscription
    },
    planList(){
      return this.$store.state.catalog.plans.filter(p => p.billing_period == this.billingPeriod)
    },
  },
  methods:{
    cancelSubscription() {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to cancel this subscription. Note: prorated charges will be applied.",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/subscriptions/cancel')
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("SET_SUBSCRIPTION", response.data.data)
            }
          })
        }
      });
    },
    resumeSubscription() {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to resume this subscription",
        reverseButtons:true,
        showCancelButton: true,
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/subscriptions/resume')
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("SET_SUBSCRIPTION", response.data.data)
            }
          })
        }
      });
    },
  },
  created(){
    this.$store.dispatch("fetchPlans")
    this.$store.dispatch("fetchSubscription")
    .then((_) => { this.isLoading = false})
  }
}

</script>

<style lang="scss">

</style>
